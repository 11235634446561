@import 'styles/colors';
@import 'styles/general';

.buttonRow {
  @include flex-grid(0.5rem);
}

.iconButton {
  @include flex-grid-item(0.5rem);
  @include flex-center;
  border-radius: 0.25rem;
  height: 40px;
  width: 40px;
  padding: 0;

  &:hover {
    box-shadow: inset 0 0 4px 4px rgba(0, 0, 0, 0.1);
  }
}

.tooltipContent {
  div {
    text-align: center;
  }

  & > div:last-child {
    font-weight: bold;
  }
}
