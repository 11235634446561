@import 'styles/colors';
@import 'styles/general';

.browserControls {
  @include flex-center;
  flex: 0 0 6em;

  &.hidden {
    .control {
      display: none;
    }
  }

  .control {
    border-radius: 1em;
    height: 0.75em;
    width: 0.75em;
    margin: 0.25em;
  }

  &.apple {
    .control {
      &.control1 {
        background: $apple-red;
        border: 1px solid $apple-red-accent;
      }

      &.control2 {
        background: $apple-yellow;
        border: 1px solid $apple-yellow-accent;
      }

      &.control3 {
        background: $apple-green;
        border: 1px solid $apple-green-accent;
      }
    }
  }

  &.mondrian {
    .control {
      border: $mondrian-border;
      height: 1em;
      width: 1em;

      &.control1 {
        background: $mondrian-red;
      }

      &.control2 {
        background: $mondrian-yellow;
      }

      &.control3 {
        background: $mondrian-blue;
      }
    }
  }

  &.windows10 {
    .control {
      margin: 0.25em 0.625em;
      position: relative;

      &.control1 {
        width: 1em;
        height: 1em;

        &:before {
          content: ' ';
          position: absolute;
          top: 50%;
          display: block;
          height: 0.125em;
          width: 100%;
        }
      }

      &.control2 {
        width: 1em;
        height: 1em;

        &:before,
        &:after {
          content: ' ';
          position: absolute;
          width: 0.5625em;
          height: 0.5625em;
          display: block;
        }
        &:before {
          top: 0;
          right: 0;
        }
        &:after {
          bottom: 0;
          left: 0;
        }
      }

      &.control3 {
        width: 1.125em;
        height: 1.125em;

        &:before,
        &:after {
          content: ' ';
          display: block;
          width: 100%;
          height: 0.125em;
          position: absolute;
        }
        &:before {
          top: 0;
          transform: translateY(0.5em) rotate(-45deg);
        }
        &:after {
          bottom: 0;
          transform: translateY(-0.5em) rotate(45deg);
        }
      }
    }

    &.light {
      .control {
        &.control1:before {
          background: $windows-dark-accent;
        }

        &.control2 {
          &:before,
          &:after {
            border: 0.125em solid $windows-dark-accent;
            background: $windows-light;
          }
        }

        &.control3 {
          &:before,
          &:after {
            background: $windows-dark-accent;
          }
        }
      }
    }

    &.dark {
      .control {
        &.control-1:before {
          background: $windows-light;
        }

        &.control2 {
          &:before,
          &:after {
            border: 0.125em solid $windows-light;
            background: $windows-dark;
          }
        }

        &.control3 {
          &:before,
          &:after {
            background: $windows-light;
          }
        }
      }
    }
  }

  &.windows98 {
    justify-content: flex-end;
    padding-right: 0.125em;

    .control {
      width: 1.125em;
      height: 1.125em;
      @include border-outset;
      border-radius: 0;
      margin: 0.125em;
      position: relative;

      &.control1:before {
        content: ' ';
        position: absolute;
        bottom: 0.125em;
        left: 0.1875em;
        width: 0.5em;
        height: 0.125em;
        display: block;
        background: $off-black;
      }

      &.control2:before {
        content: ' ';
        position: absolute;
        top: 0.125em;
        left: 0.125em;
        width: 0.5em;
        height: 0.4375em;
        display: block;
        border: 0.0625em solid $off-black;
        border-top-width: 0.125em;
        background: transparent;
      }

      &.control3 {
        &:before,
        &:after {
          content: ' ';
          position: absolute;
          width: 0.75em;
          height: 0.125em;
          background: $off-black;
        }

        &:before {
          top: 0;
          transform: translate(0.0625em, 0.375em) rotate(-45deg);
        }

        &:after {
          bottom: 0;
          transform: translate(0.0625em, -0.375em) rotate(45deg);
        }
      }
    }
  }
}
