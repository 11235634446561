@import './colors';

$border-width: 0.125em;
$mondrian-border: $border-width solid $off-black;

$box-shadow-lg: rgba(0, 0, 0, 0.55) 0px 20px 50px 0px;
$box-shadow-md: 0 10px 32px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);

@mixin border-outset {
  border-left: 0.125em solid #ededed;
  border-top: 0.125em solid #ededed;
  border-right: 0.125em solid #404040;
  border-bottom: 0.125em solid #404040;
}

@mixin border-inset {
  border-left: 0.125em solid #404040;
  border-top: 0.125em solid #404040;
  border-right: 0.125em solid #ededed;
  border-bottom: 0.125em solid #ededed;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-grid($gutter) {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(#{$gutter} * -1);
  margin-right: calc(#{$gutter} * -0.5);
  margin-bottom: 0;
  margin-left: calc(#{$gutter} * -0.5);
}

@mixin flex-grid-item($gutter) {
  margin-top: $gutter;
  margin-right: calc(#{$gutter} * 0.5);
  margin-bottom: 0;
  margin-left: calc(#{$gutter} * 0.5);
}
