@import 'styles/colors';
@import 'styles/general';

.app {
  background-color: $off-white;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
}

.transparentBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 100;
  transition: 150ms all;

  &.inactive {
    opacity: 0;
    visibility: hidden;
  }

  &.active {
    opacity: 0.3;
    visibility: visible;
  }
}

.appBodyWrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.mobileOverlay {
  display: none;
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: $off-white;
  z-index: 1000;
  padding: 2rem;
  overflow-y: auto;

  h1 {
    font-weight: 500;
    line-height: 1.7;
    margin-top: 0;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.7;
  }
}

@media only screen and (max-width: 800px) {
  .app {
    .mobileOverlay {
      display: block;
    }

    .appBodyWrapper {
      display: none;
    }
  }
}
