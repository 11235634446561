@import 'styles/colors';
@import 'styles/general';

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.browserLoader {
  height: 5.5rem;
  width: 8rem;
  border: $mondrian-border;
  border-radius: 0.5rem;
  animation: bounce 0.4s infinite alternate;
  -webkit-animation: bounce 0.4s infinite alternate;
  background: #fff;
  z-index: 10;

  header {
    height: 1rem;
    border-bottom: $mondrian-border;
    display: flex;
    align-items: center;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 0.125rem;
      border: 2px solid #333;
      border-radius: 10px;

      &:first-child {
        margin-left: 0.5rem;
      }
    }
  }
}

.loaderFloor {
  height: 3px;
  background: #000;
  animation: blur 0.4s infinite alternate;
  margin-top: -5px;
}

.loaderFill {
  @include flex-center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

@keyframes blur {
  from {
    filter: blur(0px);
    opacity: 30%;
    width: calc(8rem - 8px);
  }

  to {
    filter: blur(16px);
    opacity: 90%;
    width: 10rem;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-20px);
  }
}
