@import 'styles/colors';
@import 'styles/general';

.rowInputContainer {
  margin-top: 1.5rem;
}

.rowInput {
  display: flex;
  background: $mondrian-blue;
  border: $mondrian-border;
  margin-top: 0.5rem;
  border-radius: 0.25rem;

  &.vertical {
    flex-direction: column;

    .rowInputOption {
      border-right: 0;
      border-bottom: $mondrian-border;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.rowInputOption {
  flex: 1;
  position: relative;
  border-right: $mondrian-border;
  @include flex-center;
  color: $off-white;
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  height: 100%;
  text-align: center;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
  }

  &.selected {
    background: $mondrian-blue-shade;
    box-shadow: inset 0 0 4px 4px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    border-right: 0;
  }
}
