@import 'styles/colors';
@import 'styles/general';

.appBody {
  flex: 1;
  position: relative;
}

.export {
  @include flex-center;
  position: relative;
  user-select: none;
  flex-shrink: 0;
  overflow: hidden;
}

.exportBoundary {
  @include flex-center;
  height: 100%;
  width: 100%;
}

.exportBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.zoomPanControl_container {
  position: absolute;
  top: 8px;
  right: 8px;
}

.zoomPanControl {
  @include flex-center;
  height: 36px;
  width: 36px;
  padding: 0;
  line-height: 1;
  border: 2px solid #222;
  border-radius: 4px;
  background-color: $mondrian-blue;
  color: $off-white;
  user-select: none;
}

.zoomPanControl_label {
  flex: 1;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}

.zoomPanControl_labelContainer {
  position: absolute;
  top: 8px;
  right: 52px;
  height: 36px;
  width: 160px;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1;
}

.zoomPanControl_reset {
  &:hover {
    background-color: $mondrian-blue-shade;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @include flex-center;
  cursor: pointer;
  border: none;
  border-left: $mondrian-border;
  outline: none;
  background: $mondrian-blue;
  color: $off-white;
  width: 34px;
  height: 100%;
  padding: 4px;
}

.zoomPanControl_plus,
.zoomPanControl_minus {
  &:hover {
    background-color: $mondrian-blue-shade;
  }

  margin-bottom: 8px;
  cursor: pointer;
}
