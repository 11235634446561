@import 'styles/colors';
@import 'styles/general';

.mobileWindow {
  &.shadowSmall {
    box-shadow: $box-shadow-md;
  }

  &.shadowLarge {
    box-shadow: $box-shadow-lg;
  }

  @include flex-center;
  z-index: 101;
}

.mobileScreenshot {
  width: 100%;
  height: auto;
}

.mobileLoadingContainer {
  padding: 36px;
}

.webFramePlaceholder {
  display: flex;
  justify-content: center;
  background: $off-white;
  width: 100%;
  height: 100%;

  .content {
    margin: 3rem;
    display: block;

    h1 {
      margin: 0 0 2rem 0;
      display: block;
      text-align: center;
      font-weight: 400;
      font-size: 2rem;
    }

    strong {
      font-weight: 600;
    }

    p {
      font-size: 1.375rem;
      line-height: 1.666;
      text-align: center;
    }
  }

  &.upload {
    background-image: linear-gradient(to right, #3498db 50%, transparent 50%),
      linear-gradient(to right, #3498db 50%, transparent 50%),
      linear-gradient(to bottom, #3498db 50%, transparent 50%),
      linear-gradient(to bottom, #3498db 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 28px 4px, 28px 4px, 4px 28px, 4px 28px;

    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 150ms all;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .uploadContent {
      display: flex;
      align-items: center;
    }

    .uploadDescription {
      font-size: 1.5rem;
      color: $mondrian-blue;
    }

    .uploadIcon {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: 1rem;
      color: $mondrian-blue;
    }
  }
}

.webFramePlaceholder.upload.mobile {
  z-index: 100;

  &.iphone-x {
    border-radius: 40px;
  }

  .upload-content {
    flex-direction: column;
  }

  .upload-icon {
    height: 4rem;
    width: 4rem;
    margin: 0 0 2rem 0;
  }

  .upload-description {
    line-height: 1.5;
    font-size: 2.25rem;
    text-align: center;
    padding: 0 2rem;
  }
}
