@import 'styles/colors';
@import 'styles/general';

.swatchContainer {
  margin-bottom: 1rem;
  height: 40px;
  display: flex;
  border: $mondrian-border;
  border-radius: 0.25rem;
  overflow: hidden;
  flex: 0 0 auto;

  .swatchContainerButton {
    @include flex-center;
    padding: 0;
    border: 0;
    border-left: $mondrian-border;
    width: 40px;
    height: 36px;

    svg {
      margin: 0;
      width: 24px;
      height: 24px;
    }
  }
}

.swatch {
  flex: 1;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  height: 100%;
  position: relative;
}

.eyeDropperButton {
  @include flex-center;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;

  span {
    font-size: 1rem;
    font-weight: 400;
  }
}

.eyeDropperIcon {
  color: inherit;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.color {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundPickerMenu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -2px;
  background: $off-white;
  z-index: 10;
  border-right: $mondrian-border;
  padding: 1rem;
  height: calc(100% - 4rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(2px);
    transition: 0.25s all ease-in;
  }

  &.closed {
    transform: translateX(-100%);
    transition: 0.25s all ease-out;
  }

  header {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    color: black;

    span {
      margin-left: 1rem;
      transition: 0.08s ease-out;
    }

    svg {
      transition: 120ms ease-out all;
    }

    &:hover svg {
      transform: translateX(6px);
    }
  }
}

.gradientPicker {
  width: 100%;
  background: white;
  z-index: 50;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding: 0 0.125rem 0.25rem 0.125rem;
  flex-grow: 0;
  flex-shrink: 1;
  border: $mondrian-border;
}

.gradientTile {
  width: calc(50% - 0.25rem);
  height: 5rem;
  cursor: pointer;
  margin: 0.25rem 0.125rem 0 0.125rem;
  transition: ease-out all 0.1s;
  border: 0 solid $mondrian-blue;

  &:hover {
    border-width: 4px;
  }
}

.backgroundPickerRowPicker {
  margin-top: 0.75rem;
}

.backgroundImageContainer {
  width: 100%;
  border: $mondrian-border;
  border-radius: 0.25rem;
  position: relative;
  display: flex;

  &:before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: calc(9 / 16 * 100%);
  }

  .backgroundLoader {
    position: absolute;
    background: $off-white;
  }

  .sidebarBackgroundImage {
    max-width: 100%;
    object-fit: 'cover';
  }

  .attribution {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    border-top-left-radius: 0.25rem;
    color: $off-white;
    background-color: #222222cc;
    padding: 0.25rem 0.375rem;
    font-size: 0.75rem;

    a {
      color: $off-white;
    }
  }
}

.uploadBackgroundButton {
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-weight: normal;
  border-radius: 0.25rem;

  svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
  }
}
