@import 'styles/colors';
@import 'styles/general';

.imageUploadButton {
  border-left: $mondrian-border;
  @include flex-center;
  padding: 1rem;
  background: $mondrian-blue;
  color: $off-white;
  transition: 80ms ease-out all;
  cursor: pointer;

  &:hover {
    background: $mondrian-blue-shade;
  }

  span {
    font-size: 1.25rem;
    margin-right: 1rem;
  }
}

.imageUploadIcon {
  fill: $off-white;
  height: 32px;
  width: 32px;
  margin: 0 0.25rem;
}

@media only screen and (max-width: 800px) {
  .imageUploadButton {
    display: none;
  }
}
